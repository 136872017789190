
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import AvatarUpload from "@/components/ui/AvatarUpload.vue";
import AppInput from "@/components/Form/AppInput.vue";
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import { ILocationCountry } from "@/store/locations";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
type UserData = {
  model: string;
  id: string;
  label: string;
  status: object | null;
  type: string;
  disabled?: boolean;
};

interface editData {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdAt: string;
  device: string;
  server: string;
  emailVerified: boolean;
}
export default defineComponent({
  name: "AccountInfo",
  components: {
    AppInputsValidationProvider,
    AppInput,
    AvatarUpload,
    SvgIcon,
    AppButton
  },
  emits: {
    uploadAvatar: null,
    editUser: null
  },
  data() {
    return {
      userInform: {
        id: this.userId,
        firstName: this.userInformation?.firstName,
        lastName: this.userInformation?.lastName,
        email: this.userInformation?.email,
        phone: this.userInformation?.phone
      },
      editable: false,
      avatar: "",
      editData: {} as editData,
      valid: false
    };
  },
  props: {
    userInformation: {
      type: Object as PropType<any>,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    userType: {
      type: String,
      required: true
    }
  },
  computed: {},
  watch: {
    userInformation: {
      handler({
        firstName = "",
        lastName = "",
        email,
        phone,
        createdAt,
        emailVerified,
        registerIP,
        registerCountry,
        device,
        server
      }: {
        id: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        phone?: string;
        emailVerified?: boolean;
        createdAt?: string;
        registerIP?: string;
        registerCountry?: ILocationCountry;
        device?: { name: string; os: string };
        server: string;
      }) {
        this.editData = {
          id: this?.userId,
          firstName: firstName ?? "",
          lastName: lastName ?? "",
          email: email ?? "",
          emailVerified: emailVerified ?? false,
          phone: phone ?? "",
          createdAt: createdAt ?? "",
          registerIP: registerIP ?? "",
          registerCountry: registerCountry ? registerCountry.name : "",
          device: device ? device.name : "",
          server: server ?? ""
        } as editData;
      },
      immediate: true
    },
    editData: {
      handler(data) {},
      immediate: true,
      deep: true
    }
  },
  methods: {
    async fileUpload(avatar: any): Promise<void> {
      this.avatar = avatar;
    },
    async endEdit() {
      this.editable = false;
      const { id, email, firstName, lastName, phone } = this.editData;
      if (this.avatar !== "") {
        await this.$emit("uploadAvatar", this.avatar);
      }
      if (id) {
        await this.$emit("editUser", {
          id: Number(id),
          email,
          firstName,
          lastName,
          phone
        });
      }
    },
    cancelEditHandler() {
      this.editData = Object.assign(this.editData, this.userInform);
      this.editable = false;
    },
    changeValidationStatus(err: boolean) {
      this.valid = err;
    }
  }
});
